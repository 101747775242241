import { CodeBracketIcon } from '@heroicons/react/24/solid';
import { useState } from "react";
import React from "react";
import { projects, filterOptions } from "../data";

export default function Projects() {

    const [projectDisplay, setProjectDisplay] = useState(projects);

    function filterProjects(event) {
        if (event.target.value.includes("All")) {
            setProjectDisplay(projects);
        } else {
            setProjectDisplay(projects.filter((project) => project.category.includes(event.target.value)));
        }
    }

    return (
        <section id="projects" className="text-gray-400 bg-gray-900 body-font">
            <div className="container px-5 py-3 mx-auto text-center lg:px-40">
                <div className="flex flex-col w-full mb-10 mt-10">
                    <CodeBracketIcon className="mx-auto inline-block w-10 mb-4" />
                    <h1 className="sm:text-4xl text-3xl font-medium title-font mb-4 text-white">
                        Apps I've Built and Worked On
                    </h1>
                    <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Explicabo
                        facilis repellat ab cupiditate alias vero aliquid obcaecati quisquam
                        fuga dolore.
                    </p>
                </div>
                <div className="flex flex-wrap justify-center mb-8">
                    {filterOptions.map((option) => (
                        <button
                            key={option}
                            value={option}
                            onClick={filterProjects}
                            className="inline-flex text-white bg-blue-900 border-0 py-2 px-4 focus:outline-none hover:bg-slate-800 rounded text-base m-1">
                            {option}
                        </button>
                    ))}
                </div>
                <div className="flex flex-wrap -m-4">
                    {projectDisplay.map((project) => (
                        <a
                            href={project.link}
                            key={project.image}
                            className="sm:w-1/2 w-100 p-4">
                            <div className="flex relative">
                                <img
                                    alt="gallery"
                                    className="absolute inset-0 w-full h-full object-cover object-center"
                                    src={project.image}
                                />
                                <div className="px-8 py-10 relative z-10 w-full border-4 border-gray-800 bg-gray-900 opacity-0 hover:opacity-100">
                                    <h2 className="tracking-widest text-sm title-font font-medium text-green-700 mb-1">
                                        {project.subtitle}
                                    </h2>
                                    <h1 className="title-font text-lg font-medium text-white mb-3">
                                        {project.title}
                                    </h1>
                                    <p className="leading-relaxed">{project.description}</p>
                                </div>
                            </div>
                        </a>
                    ))}
                </div>
            </div>
        </section>
    );
}